require('./bootstrap');
require('axios');
require('bootstrap-select');

//window.Vue = require('vue');

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

/*

Vue.component('example-component',
	require('./components/ExampleComponent.vue').default
);
console.log('test');
Vue.component('button-counter', {
	data: function () {
	  return {
		count: 0
	  }
	},
	template: '<button v-on:click="count++">You clicked me {{ count }} times.</button>'
});
const app = new Vue({
	el: '#app',
	data: {
		test: 1
	}
});

console.log('init');
console.log(Vue.options.components);
*/